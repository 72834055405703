import axios from 'axios'
axios.defaults.headers.common['X-CSRF-Token'] = document.head.querySelector('[name=csrf-token]').content

const formatExtension = (format) => (format === 'html' ? '' : `.${format}`)

// ROUTES
// FORM
// export const getNextQuestionPath = (answerId, options = { format: 'json' }) =>
//   `/api/answers/${answerId}/next_question${formatExtension(options.format)}`

// export const getNextQuestion = (answerId, params) =>
//   axios.get(getNextQuestionPath(answerId), params)

export const getSocialSecurityNumberKeyPath = (options = { format: 'json' }) =>
  `/api/social_security_number_key${formatExtension(options.format)}`
export const getSocialSecurityNumberKey = (ssNumber) =>
  axios.get(getSocialSecurityNumberKeyPath(), { params: { number: ssNumber } })

export const selectProfessionPath = (contractId, options = { format: 'json' }) =>
  `/wizard/contracts/${contractId}/contract_professions${formatExtension(options.format)}`

export const selectProfession = (contractId, params) =>
  axios.post(selectProfessionPath(contractId), params)

export const getSlotFormPath = (contractId, options = { format: 'json' }) =>
  `/api/contracts/${contractId}/slots/new${formatExtension(options.format)}`

export const getSlotForm = (contractId, params) =>
  axios.get(getSlotFormPath(contractId), params)

// DISPLAY
export const postFlashAlertPath = (options = { format: 'json' }) =>
  `/display_flash${formatExtension(options.format)}`

export const postFlashAlert = (params) =>
  axios.post(postFlashAlertPath(), params)

// NEW REFONTE UX
// ROUTING FRONT
export const getRootPath = (options = { format: 'html' }) => {
  return `/${formatExtension(options.format)}`
}

export const getNewContractPath = (options = { format: 'json' }) => {
  return `/wizard/contracts/new${formatExtension(options.format)}`
}
export const getEditContractProjectPath = (contractId, options = { format: 'json' }) => {
  return `/wizard/contracts/${contractId}/project${formatExtension(options.format)}`
}
export const getEditContractSlotsPath = (contractId, options = { format: 'json' }) => {
  return `/wizard/contracts/${contractId}/slots${formatExtension(options.format)}`
}
export const getShowContractSlotsPath = (contractId, options = { format: 'json' }) => {
  return `/wizard/contracts/${contractId}/show_slots${formatExtension(options.format)}`
}
export const getContractSignaturePath = (contractId, options = { format: 'json' }) => {
  return `/wizard/contracts/${contractId}/signature${formatExtension(options.format)}`
}
export const getDashboardPath = (options = { format: 'json' }) => {
  return `/account${formatExtension(options.format)}`
}
export const getNewMemberPath = (options = { format: 'json' }) => {
  return `/members/sign_up${formatExtension(options.format)}`
}
export const getContractDashboardPath = (contractId, options = { format: 'json' }) => {
  return `/account/contracts/${contractId}${formatExtension(options.format)}`
}
export const getCompanyDashboardPath = (options = { format: 'json' }) => {
  return `/account/company${formatExtension(options.format)}`
}
export const getEmployeesDashboardPath = (options = { format: 'json' }) => {
  return `/account/employees${formatExtension(options.format)}`
}
export const getDocumentsDashboardPath = (options = { format: 'json' }) => {
  return `/account/documents${formatExtension(options.format)}`
}
export const getPaymentsDashboardPath = (options = { format: 'json' }) => {
  return `/account/payments${formatExtension(options.format)}`
}
export const getMemberOnboardingPath = (options = { format: 'json' }) => {
  return `/members/onboarding${formatExtension(options.format)}`
}
export const getUserOnboardingPath = (options = { format: 'json' }) => {
  return `/users/onboarding${formatExtension(options.format)}`
}
export const getCompanyLegalPath = (options = { format: 'json' }) => {
  return `/account/company/legal${formatExtension(options.format)}`
}
export const getCompanyContactPath = (options = { format: 'json' }) => {
  return `/account/company/contact${formatExtension(options.format)}`
}
export const getCompanyInvoicingPath = (options = { format: 'json' }) => {
  return `/account/company/invoicing${formatExtension(options.format)}`
}
export const getCompanyAffiliationPath = (options = { format: 'json' }) => {
  return `/account/company/affiliation${formatExtension(options.format)}`
}
export const getCompanyParitarismPath = (options = { format: 'json' }) => {
  return `/account/company/paritarism${formatExtension(options.format)}`
}
export const getCompanyPaymentPath = (options = { format: 'json' }) => {
  return `/account/company/payment${formatExtension(options.format)}`
}
export const getCompanyParametersPath = (options = { format: 'json' }) => {
  return `/account/company/parameters${formatExtension(options.format)}`
}
export const getProfilePath = (options = { format: 'json' }) => {
  return `/account/profile${formatExtension(options.format)}`
}
export const getContractsListDashboardPath = (options = { format: 'json' }) => {
  return `/account/contracts_list${formatExtension(options.format)}`
}

// API
// Current Member
export const getCurrentMemberPath = (options = { format: 'json' }) =>
  `/api/v1/current_member${formatExtension(options.format)}`
export const getCurrentMember = (params) =>
  axios.get(getCurrentMemberPath(), params)

// Current User
export const getCurrentUserPath = (options = { format: 'json' }) =>
  `/api/v1/current_user${formatExtension(options.format)}`
export const getCurrentUser = (params) =>
  axios.get(getCurrentUserPath(), params)

export const getCurrentMemberIdPath = (options = { format: 'json' }) =>
  `/api/v1/current_member/get_id${formatExtension(options.format)}`
export const getCurrentMemberId = (params) =>
  axios.get(getCurrentMemberIdPath(), params)

export const destroyCurrentUserSessionPath = (options = { format: 'html' }) =>
  `/api/v1/users/sign_out${formatExtension(options.format)}`
export const destroyCurrentUserSession = (params) =>
  axios.delete(destroyCurrentUserSessionPath(), params)

// Liste de ses contrats
export const getProjectsPath = (memberId, options = { format: 'json' }) =>
  `/api/v1/members/${memberId}/projects${formatExtension(options.format)}`
export const getProjects = (memberId, params) =>
  axios.get(getProjectsPath(memberId), params)

// Copie d'un contrat
export const duplicateContractPath = (newContractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${newContractId}/clone_attributes${formatExtension(options.format)}`
export const duplicateContract = (newContractId, params) =>
  axios.post(duplicateContractPath(newContractId), params)

// Création d'un Member
export const putMemberPath = (memberId, options = { format: 'json' }) =>
  `/api/v1/members/${memberId}${formatExtension(options.format)}`
export const putMember = (memberId, params) =>
  axios.put(putMemberPath(memberId), params)

// Création d'un User
export const putUserPath = (userId, options = { format: 'json' }) =>
  `/api/v1/users/${userId}${formatExtension(options.format)}`
export const putUser = (userId, params) =>
  axios.put(putUserPath(userId), params)

export const postUserMemberChoicePath = (userId, options = { format: 'json' }) =>
  `/api/v1/users/${userId}/save_member_choice${formatExtension(options.format)}`
export const postUserMemberChoice = (userId, params) =>
  axios.post(postUserMemberChoicePath(userId), params)

// Enregistrement de la page en cours dans le funnel de qualitication du Membre
export const putMemberOnboardingStatePath = (memberId, options = { format: 'json' }) =>
  `/api/v1/members/${memberId}/set_onboarding_state${formatExtension(options.format)}`
export const putMemberOnboardingState = (memberId, params) =>
  axios.put(putMemberOnboardingStatePath(memberId), params)

// Liste des employés
export const getEmployeesPath = (memberId, options = { format: 'json' }) =>
  `/api/v1/members/${memberId}/employees${formatExtension(options.format)}`
export const getEmployees = (memberId, params) =>
  axios.get(getEmployeesPath(memberId), params)

// Wizard Intermittent
export const getNewEmployeePath = (memberId, options = { format: 'json' }) =>
  `/api/v1/members/${memberId}/employees/new${formatExtension(options.format)}`
export const getNewEmployee = (memberId, params) =>
  axios.get(getNewEmployeePath(memberId), params)

export const postEmployeePath = (memberId, options = { format: 'json' }) =>
  `/api/v1/members/${memberId}/employees${formatExtension(options.format)}`
export const postEmployee = (memberId, params) =>
  axios.post(postEmployeePath(memberId), params)

export const postEmployeeContactDetailsPath = (memberId, options = { format: 'json' }) =>
  `/api/v1/members/${memberId}/employees/contact_details${formatExtension(options.format)}`
export const postEmployeeContactDetails = (memberId, params) =>
  axios.post(postEmployeeContactDetailsPath(memberId), params)

export const postEmployeeBirthDetailsPath = (memberId, options = { format: 'json' }) =>
  `/api/v1/members/${memberId}/employees/birth_details${formatExtension(options.format)}`
export const postEmployeeBirthDetails = (memberId, params) =>
  axios.post(postEmployeeBirthDetailsPath(memberId), params)

export const postEmployeeSocialDetailsPath = (memberId, options = { format: 'json' }) =>
  `/api/v1/members/${memberId}/employees/social_details${formatExtension(options.format)}`
export const postEmployeeSocialDetails = (memberId, params) =>
  axios.post(postEmployeeSocialDetailsPath(memberId), params)

export const postEmployeeTaxDetailsPath = (memberId, options = { format: 'json' }) =>
  `/api/v1/members/${memberId}/employees/tax_details${formatExtension(options.format)}`
export const postEmployeeTaxDetails = (memberId, params) =>
  axios.post(postEmployeeTaxDetailsPath(memberId), params)

export const postEmployeeRibDetailsPath = (memberId, options = { format: 'json' }) =>
  `/api/v1/members/${memberId}/employees/rib_details${formatExtension(options.format)}`
export const postEmployeeRibDetails = (memberId, params) =>
  axios.post(postEmployeeRibDetailsPath(memberId), params)

export const getIbanNumberUniquenessPath = (memberId, options = { format: 'json' }) =>
  `/api/v1/members/${memberId}/employees/iban_number_uniqueness${formatExtension(options.format)}`
export const getIbanNumberUniqueness = (memberId, params) =>
  axios.get(getIbanNumberUniquenessPath(memberId), params)

export const putEmployeeContactDetailsPath = (employeeId, options = { format: 'json' }) =>
  `/api/v1/employees/${employeeId}/update_contact_details${formatExtension(options.format)}`
export const putEmployeeContactDetails = (employeeId, params) =>
  axios.put(putEmployeeContactDetailsPath(employeeId), params)

// Birth details
export const putEmployeeBirthDetailsPath = (employeeId, options = { format: 'json' }) =>
  `/api/v1/employees/${employeeId}/update_birth_details${formatExtension(options.format)}`
export const putEmployeeBirthDetails = (employeeId, params) =>
  axios.put(putEmployeeBirthDetailsPath(employeeId), params)

// Social details
export const putEmployeeSocialDetailsPath = (employeeId, options = { format: 'json' }) =>
  `/api/v1/employees/${employeeId}/update_social_details${formatExtension(options.format)}`
export const putEmployeeSocialDetails = (employeeId, params) =>
  axios.put(putEmployeeSocialDetailsPath(employeeId), params)

// Tax details
export const putEmployeeTaxDetailsPath = (employeeId, options = { format: 'json' }) =>
  `/api/v1/employees/${employeeId}/update_tax_details${formatExtension(options.format)}`
export const putEmployeeTaxDetails = (employeeId, params) =>
  axios.put(putEmployeeTaxDetailsPath(employeeId), params)

// Rib details
export const putEmployeeRibDetailsPath = (employeeId, options = { format: 'json' }) =>
  `/api/v1/employees/${employeeId}/update_rib_details${formatExtension(options.format)}`
export const putEmployeeRibDetails = (employeeId, params) =>
  axios.put(putEmployeeRibDetailsPath(employeeId), params)

// Show d'un intermittent
export const getEmployeePath = (employeeId, options = { format: 'json' }) =>
  `/api/v1/employees/${employeeId}${formatExtension(options.format)}`
export const getEmployee = (employeeId, params) =>
  axios.get(getEmployeePath(employeeId), params)

// Création d'un contrat avec assignation intermittent
export const createContractPath = (options = { format: 'json' }) =>
  `/api/v1/contracts${formatExtension(options.format)}`
export const createContract = (params) =>
  axios.post(createContractPath(), params)

// Get contract
export const getContractPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}${formatExtension(options.format)}`
export const getContract = (contractId, params) =>
  axios.get(getContractPath(contractId), params)

export const getContractWizardPayloadPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/wizard_payload${formatExtension(options.format)}`
export const getContractWizardPayload = (contractId, params) =>
  axios.get(getContractWizardPayloadPath(contractId), params)

export const getContractDashboardPayloadPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/dashboard_payload${formatExtension(options.format)}`
export const getContractDashboardPayload = (contractId, params) =>
  axios.get(getContractDashboardPayloadPath(contractId), params)

export const getSiloPayloadPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/silo_payload${formatExtension(options.format)}`
export const getSiloPayload = (contractId, params) =>
  axios.get(getSiloPayloadPath(contractId), params)

export const getContractSignaturePayloadPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/signature_payload${formatExtension(options.format)}`
export const getContractSignaturePayload = (contractId, params) =>
  axios.get(getContractSignaturePayloadPath(contractId), params)

export const getContractCardPayloadPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/contract_card_payload${formatExtension(options.format)}`
export const getContractCardPayload = (contractId, params) =>
  axios.get(getContractCardPayloadPath(contractId), params)

export const getTimepagePayloadPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/timepage_payload${formatExtension(options.format)}`
export const getTimepagePayload = (contractId, params) =>
  axios.get(getTimepagePayloadPath(contractId), params)

// Update d'un contrat
export const updateContractPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}${formatExtension(options.format)}`
export const updateContract = (contractId, params) =>
  axios.put(updateContractPath(contractId), params)

export const putTimeAndPayQualifiedContractPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/mark_as_time_and_pay_qualified${formatExtension(options.format)}`
export const putTimeAndPayQualifiedContract = (contractId, params) =>
  axios.put(putTimeAndPayQualifiedContractPath(contractId), params)

export const putContractReadyToSignedPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/mark_as_contract_ready_to_sign${formatExtension(options.format)}`
export const putContractReadyToSigned = (contractId, params) =>
  axios.put(putContractReadyToSignedPath(contractId), params)

export const putCustomizedAndSignedContractPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/mark_as_customized_and_signed${formatExtension(options.format)}`
export const putCustomizedAndSignedContract = (contractId, params) =>
  axios.put(putCustomizedAndSignedContractPath(contractId), params)

export const putSignYousignProcedurePath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/sign_yousign_procedure${formatExtension(options.format)}`
export const putSignYousignProcedure = (contractId, params) =>
  axios.put(putSignYousignProcedurePath(contractId), params)

export const generateContractPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/generate_contract${formatExtension(options.format)}`
export const generateContract = (contractId, params) =>
  axios.put(generateContractPath(contractId), params)

export const sendContractToSignatoryPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/send_contract_to_signatory${formatExtension(options.format)}`
export const sendContractToSignatory = (contractId, params) =>
  axios.put(sendContractToSignatoryPath(contractId), params)

export const deleteContractPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/cancel${formatExtension(options.format)}`
export const deleteContract = (contractId, params) =>
  axios.put(deleteContractPath(contractId), params)

export const declareDirectSalaryPaymentPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/declare_direct_salary_payment${formatExtension(options.format)}`
export const declareDirectSalaryPayment = (contractId, params) =>
  axios.get(declareDirectSalaryPaymentPath(contractId), params)

export const initializeManualSalaryPaymentPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/initialize_manual_salary_payment${formatExtension(options.format)}`
export const initializeManualSalaryPayment = (contractId, params) =>
  axios.get(initializeManualSalaryPaymentPath(contractId), params)

export const resetContractPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/reset${formatExtension(options.format)}`
export const resetContract = (contractId, params) =>
  axios.put(resetContractPath(contractId), params)

export const putContractObjectNumberPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/update_object_number${formatExtension(options.format)}`
export const putContractObjectNumber = (contractId, params) =>
  axios.put(putContractObjectNumberPath(contractId), params)

// Project
export const postContractProjectPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/contract_projects${formatExtension(options.format)}`
export const postContractProject = (contractId, params) =>
  axios.post(postContractProjectPath(contractId), params)

// Silo
export const getNextQuestionPath = (answerId, options = { format: 'json' }) =>
  `/api/v1/answers/${answerId}/next_question${formatExtension(options.format)}`
export const getNextQuestion = (answerId, params) =>
  axios.get(getNextQuestionPath(answerId), params)

export const getAnswerPath = (answerId, options = { format: 'json' }) =>
  `/api/v1/answers/${answerId}${formatExtension(options.format)}`
export const getAnswer = (answerId, params) =>
  axios.get(getAnswerPath(answerId), params)

// Update Silo
export const postContractSiloPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/contract_projects/update_silo${formatExtension(options.format)}`
export const postContractSilo = (contractId, params) =>
  axios.put(postContractSiloPath(contractId), params)

export const putContractProfessionPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/contract_projects/update_profession${formatExtension(options.format)}`
export const putContractProfession = (contractId, params) =>
  axios.put(putContractProfessionPath(contractId), params)

export const putContractExecutiveCategoryPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/contract_projects/update_executive_category${formatExtension(options.format)}`
export const putContractExecutiveCategory = (contractId, params) =>
  axios.put(putContractExecutiveCategoryPath(contractId), params)

export const getProfessionPath = (professionId, options = { format: 'json' }) =>
  `/api/v1/professions/${professionId}${formatExtension(options.format)}`
export const getProfession = (professionId, params) =>
  axios.get(getProfessionPath(professionId, params))

// New Slot
export const postSlotPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/slots`
export const postSlot = (contractId, params) =>
  axios.post(postSlotPath(contractId), params)

export const editSlotPath = (slotId, options = { format: 'json' }) =>
  `/api/v1/slots/${slotId}`
export const editSlot = (slotId, params) =>
  axios.put(editSlotPath(slotId), params)

export const getInslotPath = (inslotId, options = { format: 'json' }) =>
  `/api/v1/inslots/${inslotId}${formatExtension(options.format)}`
export const getInslot = (inslotId, params) =>
  axios.get(getInslotPath(inslotId), params)

export const postSlotFormPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/slot_form${formatExtension(options.format)}`
export const postSlotForm = (contractId, params) =>
  axios.post(postSlotFormPath(contractId), params)

export const updateSlotFormPath = (slotId, options = { format: 'json' }) =>
  `/api/v1/slots/${slotId}/slot_form${formatExtension(options.format)}`
export const updateSlotForm = (slotId, params) =>
  axios.put(updateSlotFormPath(slotId), params)

// Get slot
export const getSlotPath = (slotId, options = { format: 'json' }) =>
  `/api/v1/slots/${slotId}${formatExtension(options.format)}`
export const getSlot = (slotId, params) =>
  axios.get(getSlotPath(slotId), params)

// Delete slot
export const deleteSlotPath = (slotId, options = { format: 'json' }) =>
  `/api/v1/slots/${slotId}${formatExtension(options.format)}`
export const deleteSlot = (slotId, params) =>
  axios.delete(deleteSlotPath(slotId), params)

// Calculs
export const getComputedAmountsPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/temp_amounts${formatExtension(options.format)}`
export const getComputedAmounts = (contractId, params) =>
  axios.get(getComputedAmountsPath(contractId), params)

// Documents
export const getDocumentPath = (documentId, options = { format: 'json' }) =>
  `/api/v1/cp_documents/${documentId}${formatExtension(options.format)}`
export const getDocument = (documentId, params) =>
  axios.get(getDocumentPath(documentId), params)

// DASHBOARD

// Monthly Dashboard
export const getMonthlyPayloadPath = (memberId, options = { format: 'json' }) =>
  `/api/v1/members/${memberId}/monthly_payload${formatExtension(options.format)}`
export const getMonthlyPayload = (memberId, transferCodeId) =>
  axios.get(getMonthlyPayloadPath(memberId), { params: { transferCodeId: transferCodeId } })

// Documents Dashboard
export const getTransferCodesListPath = (memberId, options = { format: 'json' }) =>
  `/api/v1/members/${memberId}/transfer_codes${formatExtension(options.format)}`
export const getTransferCodesList = (memberId, params) =>
  axios.get(getTransferCodesListPath(memberId), params)

export const getDocumentsListPath = (transferCodeId, options = { format: 'json' }) =>
  `/api/v1/transfer_codes/${transferCodeId}/cp_documents${formatExtension(options.format)}`
export const getDocumentsList = (transferCodeId, params) =>
  axios.get(getDocumentsListPath(transferCodeId), params)

// Close transfer_code
export const closeTransferCodePath = (transferCodeId, options = { format: 'json' }) =>
  `/api/v1/transfer_codes/${transferCodeId}/close${formatExtension(options.format)}`
export const closeTransferCode = (transferCodeId, params) =>
  axios.put(closeTransferCodePath(transferCodeId), params)

export const getTransferCodeContractsPath = (transferCodeId, options = { format: 'json' }) =>
  `/api/v1/transfer_codes/${transferCodeId}/contracts${formatExtension(options.format)}`
export const getTransferCodeContracts = (transferCodeId, params) =>
  axios.get(getTransferCodeContractsPath(transferCodeId), params)

// Actions from dashboard
export const askForSignaturePath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/ask_for_signature${formatExtension(options.format)}`
export const askForSignature = (contractId, params) =>
  axios.post(askForSignaturePath(contractId), params)

export const sendDocumentsToEmployeePath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/resend_documents_to_employee${formatExtension(options.format)}`
export const sendDocumentsToEmployee = (contractId, params) =>
  axios.post(sendDocumentsToEmployeePath(contractId), params)

export const validateAndSendDocumentsToEmployeePath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/validate_and_send_documents_to_employee${formatExtension(options.format)}`
export const validateAndSendDocumentsToEmployee = (contractId, params) =>
  axios.post(validateAndSendDocumentsToEmployeePath(contractId), params)

export const downloadPaymentSummaryPath = (transferCodeId, options = { format: 'html' }) =>
  `/api/v1/transfer_codes/${transferCodeId}/download_payment_summary${formatExtension(options.format)}`
export const downloadPaymentSummary = (transferCodeId, params) =>
  axios.get(downloadDocumentsPath(transferCodeId), params)

export const downloadInvoicePath = (transferCodeId, options = { format: 'html' }) =>
  `/api/v1/transfer_codes/${transferCodeId}/download_invoice${formatExtension(options.format)}`
export const downloadInvoice = (transferCodeId, params) =>
  axios.get(downloadInvoicePath(transferCodeId), params)

export const downloadDocumentsPath = (contractId, options = { format: 'html' }) =>
  `/api/v1/contracts/${contractId}/download_documents${formatExtension(options.format)}`
export const downloadDocuments = (contractId, params) =>
  axios.get(downloadDocumentsPath(contractId), params)

export const downloadTransferCodeDocumentsPath = (transferCodeId, options = { format: 'html' }) =>
  `/api/v1/transfer_codes/${transferCodeId}/download_documents${formatExtension(options.format)}`
export const downloadTransferCodeDocuments = (transferCodeId, params) =>
  axios.get(downloadTransferCodeDocumentsPath(transferCodeId), params)

export const sendTransferCodeDocumentsPath = (transferCodeId, options = { format: 'html' }) =>
  `/api/v1/transfer_codes/${transferCodeId}/send_documents_to_accounting${formatExtension(options.format)}`
export const sendTransferCodeDocuments = (transferCodeId, params) =>
  axios.get(sendTransferCodeDocumentsPath(transferCodeId), params)

// Cashflows

export const getCashflowsListPath = (transferCodeId, options = { format: 'json' }) =>
  `/api/v1/transfer_codes/${transferCodeId}/cashflows${formatExtension(options.format)}`
export const getCashflowsList = (transferCodeId, params) =>
  axios.get(getCashflowsListPath(transferCodeId), params)

export const sendPaymentSummaryPath = (transferCodeId, options = { format: 'json' }) =>
  `/api/v1/transfer_codes/${transferCodeId}/send_payment_summary${formatExtension(options.format)}`
export const sendPaymentSummary = (transferCodeId, params) =>
  axios.get(sendPaymentSummaryPath(transferCodeId), params)

// PARAMETERS
export const getCompanyFormPayloadPath = (options = { format: 'json' }) =>
  `/api/v1/members/onboarding_payload${formatExtension(options.format)}`
export const getCompanyFormPayload = (params) =>
  axios.get(getCompanyFormPayloadPath(), params)

export const getCollectiveAgreementPath = (collectiveAgreementId, options = { format: 'json' }) =>
  `/api/v1/collective_agreements/${collectiveAgreementId}${formatExtension(options.format)}`
export const getCollectiveAgreement = (collectiveAgreementId, params) =>
  axios.get(getCollectiveAgreementPath(collectiveAgreementId), params)

export const getWorkAccidentCodesPath = (options = { format: 'json' }) =>
  `/api/v1/work_accident_codes${formatExtension(options.format)}`
export const getWorkAccidentCodes = (params) =>
  axios.get(getWorkAccidentCodesPath(), params)

export const getMemberInvitationsPath = (options = { format: 'json' }) => {
 return `/account/invitations${formatExtension(options.format)}`
}

// Inscription
export const postUserEmployeeChoicePath = (userId, options = { format: 'json' }) =>
  `/api/v1/users/${userId}/save_employee_choice${formatExtension(options.format)}`
export const postUserEmployeeChoice = (userId, params) =>
  axios.post(postUserEmployeeChoicePath(userId), params)

// Switch de compte
export const destroyCurrentMemberSessionPath = (options = { format: 'html' }) =>
  `/members/sign_out${formatExtension(options.format)}`
export const destroyCurrentMemberSession = (params) =>
  axios.delete(destroyCurrentMemberSessionPath(), params)

export const createCurrentMemberSessionPath = (options = { format: 'html' }) =>
  `/members/sign_in${formatExtension(options.format)}`
export const createCurrentMemberSession = (params) =>
  axios.post(createCurrentMemberSessionPath(), params)

// Employee space
export const getEmployeeDashboardPath = (options = { format: 'json' }) => {
  return `/employee_account${formatExtension(options.format)}`
}
// Sidebar Routing
export const getEmployersPath = (options = { format: 'json' }) => {
  return `/employee_account/employers${formatExtension(options.format)}`
}
export const getEmployeeInvitationsPath = (options = { format: 'json' }) => {
 return `/employee_account/invitations${formatExtension(options.format)}`
}
export const getEmployeeContractDashboardPath = (contractId, options = { format: 'json' }) => {
  return `/employee_account/contracts/${contractId}${formatExtension(options.format)}`
}
// Onglet Profile
export const getEmployeeProfilePath = (options = { format: 'json' }) => {
  return `/employee_account/profile${formatExtension(options.format)}`
}
export const getEmployeeProfileLoginInfosPath = (options = { format: 'json' }) => {
  return `/employee_account/employee_profile/login_infos${formatExtension(options.format)}`
}
export const getEmployeeProfileAddSpacePath = (options = { format: 'json' }) => {
  return `/employee_account/employee_profile/add_space${formatExtension(options.format)}`
}

// API
export const getCurrentEmployeePath = (options = { format: 'json' }) =>
  `/api/v1/current_employee${formatExtension(options.format)}`
export const getCurrentEmployee = (params) =>
  axios.get(getCurrentEmployeePath(), params)

// Monthly dashboard
export const getEmployeeMonthlyPayloadPath = (options = { format: 'json' }) =>
  `/api/v1/employee_account/monthly_dashboard${formatExtension(options.format)}`
export const getEmployeeMonthlyPayload = (selectedMonth) =>
  axios.get(getEmployeeMonthlyPayloadPath(), { params: { selectedMonth: selectedMonth } })

export const getEmployersListPath = (options = { format: 'json' }) =>
  `/api/v1/employee_account/employers${formatExtension(options.format)}`
export const getEmployersList = (params) =>
  axios.get(getEmployersListPath(), params)

export const downloadEmployeeMonthlyDocumentsPath = (selectedMonth, options = { format: 'html' }) =>
  `/api/v1/employee_account/download_monthly_documents/${selectedMonth}${formatExtension(options.format)}`

// Contracts
export const getEmployeeContractDashboardPayloadPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/employee_account/contracts/${contractId}/dashboard_payload${formatExtension(options.format)}`
export const getEmployeeContractDashboardPayload = (contractId, params) =>
  axios.get(getEmployeeContractDashboardPayloadPath(contractId), params)

export const downloadEmployeeContractDocuments = (contractId, options = { format: 'html' }) =>
  `/api/v1/employee_account/contracts/${contractId}/download_employee_documents${formatExtension(options.format)}`

export const sendEmailToEmployerPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/employee_account/contracts/${contractId}/send_email_to_employer${formatExtension(options.format)}`
export const sendEmailToEmployer = (contractId, content) =>
  axios.get(sendEmailToEmployerPath(contractId), { params: { content: content } })

// Documents
export const getEmployeeDocumentPath = (documentId, options = { format: 'json' }) =>
  `/api/v1/employee_account/cp_documents/${documentId}${formatExtension(options.format)}`
export const getEmployeeDocument = (documentId, params) =>
  axios.get(getEmployeeDocumentPath(documentId), params)

// Devise confirmation
export const sendEmailConfirmationPath = (userId, options = { format: 'json' }) =>
  `/users/${userId}/send_confirmation_email${formatExtension(options.format)}`
export const sendEmailConfirmation = (userId) =>
  axios.post(sendEmailConfirmationPath(userId))

// SIMULATOR
export const getSimulatorPath = (options = { format: 'json' }) =>
  `/simulator/new${formatExtension(options.format)}`

export const getSimulatorInitialStatePath = (options = { format: 'json' }) =>
  `/simulator/simulator_initial_state${formatExtension(options.format)}`
export const getSimulatorInitialState = (params) =>
  axios.get(getSimulatorInitialStatePath(), { params: { simulation: params } })

export const getCollectiveAgreementsPath = (options = { format: 'json' }) =>
  `/simulator/collective_agreements${formatExtension(options.format)}`
export const getCollectiveAgreements = (params) =>
  axios.get(getCollectiveAgreementsPath(), { params: { simulation: params } })

export const setSimulatorCollectiveAgreementPath = (options = { format: 'json' }) =>
  `/simulator/set_collective_agreement${formatExtension(options.format)}`
export const setSimulatorCollectiveAgreement = (params) =>
  axios.get(setSimulatorCollectiveAgreementPath(), { params: { simulation: params } })

export const getSimulatorProfessionsPath = (options = { format: 'json' }) =>
  `/simulator/professions${formatExtension(options.format)}`
export const getSimulatorProfessions = (params) =>
  axios.get(getSimulatorProfessionsPath(), { params: { simulation: params } })

export const getSimulatorTimepagePayloadPath = (options = { format: 'json' }) =>
  `/simulator/simulator_timepage_payload${formatExtension(options.format)}`
export const getSimulatorTimepagePayload = (params) =>
  axios.get(getSimulatorTimepagePayloadPath(), { params: { silo: params } })

export const getSimulatorSlotsPath = (options = { format: 'json' }) =>
  `/simulator/simulator_slots${formatExtension(options.format)}`
export const getSimulatorSlots = (params) =>
  axios.post(getSimulatorSlotsPath(), params)

export const deleteSimulatorSlotPath = (options = { format: 'json' }) =>
  `/simulator/delete_simulator_slot${formatExtension(options.format)}`
export const deleteSimulatorSlot = (params) =>
  axios.post(deleteSimulatorSlotPath(), params)

export const getSimulatorSlotValidityPath = (options = { format: 'json' }) =>
  `/simulator/simulator_slot_validity${formatExtension(options.format)}`
export const getSimulatorSlotValidity = (params) =>
  axios.post(getSimulatorSlotValidityPath(), params)

// Contracts Dashboard

export const getContractsDashboardPayloadPath = (options = { format: 'json' }) =>
  `/api/v1/member_account/contracts_dashboard_payload${formatExtension(options.format)}`
export const getContractsDashboardPayload = () =>
  axios.get(getContractsDashboardPayloadPath())

export const getContractsIndexPath = (memberId, options = { format: 'json' }) =>
  `/api/v1/contracts${formatExtension(options.format)}`
export const getContractsIndex = (memberId, params) =>
  axios.get(getContractsIndexPath(memberId), params)

export const getContractInfoPayloadPath = (contractId, options = { format: 'json' }) =>
  `/api/v1/contracts/${contractId}/contract_info_payload${formatExtension(options.format)}`
export const getContractInfoPayload = (contractId, params) =>
  axios.get(getContractInfoPayloadPath(contractId), params)
